@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  height: auto !important;
  width: 100%;
  font-family: "Inter";
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  position: relative;
  -webkit-font-smoothing: antialiased !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea:focus-visible {
  outline: none;
}
textarea {
  resize: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.rounded-table-custom td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-table-custom td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

input[type="checkbox"] {
  border-color: var(--color-gray-400);
  border-radius: 0.25rem;
  outline: none;
  box-shadow: none;
}

input[type="checkbox"]:focus {
  border-color: var(--color-gray-400);
  border-radius: 0.25rem;
  outline: none;
  box-shadow: none;
}

input[type="checkbox"]:checked {
  background-image: url("../assests/svg/checkmark.svg");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
  outline: none;
  box-shadow: none;
}

input[type="checkbox"]:checked:hover {
  border-color: transparent;
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
  outline: none;
  box-shadow: none;
}

input[type="checkbox"]:checked:focus {
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
  outline: 0 none;
  box-shadow: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* React-select */

.select2-selection__indicator svg {
  width: 17px;
  height: 17px;
}

.select2-selection__input:focus,
.select2-selection__input:hover,
.no-heightSelecton__input:hover,
.no-heightSelecton__input:focus,
.__input:hover,
.__input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.select2-selection__input:hover,
.no-heightSelecton:hover {
  border: inherit !important;
}

.select2-selection__multi-value {
  @apply flex gap-2 !px-4 !py-1.5 text-sm !rounded-full !leading-tight justify-center;
  background-color: var(--color-tag-surface-background) !important;
  margin: 0px !important;
}

.select2-selection__multi-value__label {
  color: var(--color-tag-text) !important;
}

.select2-selection__multi-value__remove {
  color: var(--color-icon-fill-default);
}

.select2-selection__value-container {
  gap: 12px;
}

.select2-selection__option {
  padding: 7px 10px !important;
  margin-bottom: 4px !important;
   overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.certificate-date-picker > div {
  z-index: 999999 !important;
}

.taxRateType-selected {
  border: 1px solid;
  font-weight: 600;
  color: var(--color-global-theme);
  border-radius: 32px;
  background: var(--color-global-gray-brand);
}

.taxRateType-unselected {
  font-weight: 400;
  border-radius: 32px;
  color: var(--color-global-gray-brand);
  background: var(--color-global-surface);
}

/* .custom-toggle-icon {
  background-image: url("/src/assests/svg/Calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px !important;
  height: 20px !important;
  right: 14px;
  top: 12px;
} */
.custom-toggle-icon {
  background-image: url("/src/assests/svg/calendar1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px !important;
  height: 20px !important;
  right: 14px;
  top: 12px;
}

.custom-toggle-icon svg {
  display: none;
}

.MuiList-root {
  padding: 0 !important;
}

.rc-slider-track,
.rc-slider-tracks {
  background: var(--color-global-primary);
  height: 8px;
  width: 220px;
}

.rc-slider-rail {
  height: 8px;
  background: #e4e7ec;
}

.rc-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -8px;
  background: var(--color-global-white);
  border: 2px solid var(--color-global-primary);
}

.rc-slider-horizontal {
  height: 0px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-swiper .swiper-slide {
  background: transparent;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button {
  display: flex;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 10;
  cursor: pointer;
}

.signup-swiper .swiper-button {
  display: flex;
  position: absolute;
  top: auto;
  bottom: 6px;
  z-index: 10;
  cursor: pointer;
}

.signup-swiper .image-swiper-button-prev {
  left: 25%;
}

.signup-swiper .image-swiper-button-next {
  right: 25%;
}

.swiper-button svg {
  width: 1.8rem;
  height: 1.8rem;
}

.image-swiper-button-prev {
  left: 5px;
}

.image-swiper-button-next {
  right: 5px;
}

.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.signup-swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}

.signup-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.signup-swiper .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  width: 24px;
  height: 6px;
  border-radius: 8px;
  background-color: var(--color-global-primary);
}

.card-number-wrapper {
  position: relative;
}

.card-number-element {
  padding-right: 40px;
  /* Adjust based on the icon size */
}

.card-number-wrapper .__PrivateStripeElement iframe {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  pointer-events: none;
}

.phone-input-field {
  border-radius: 8px;
}
.phone-input-field:focus-within {
  border-radius: 8px;
  box-shadow: none;
  border-color: var(--color-input-stroke-active) !important;
  outline-color: var(--color-input-stroke-active) !important;
  outline: 1px solid var(--color-input-stroke-active) !important;
  transition: all 300ms ease-in-out;
}

.phone-input-field .PhoneInput .PhoneInputInput,
.phone-input-field input {
  outline: none;
  box-shadow: none;
  height: 44px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: #344054;
  background-color: transparent;
}
.phone-input-field .PhoneInput .PhoneInputInput:hover,
.phone-input-field input:hover {
  outline: none;
  height: 44px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  background-color: transparent;
}
.phone-input-field .PhoneInput .PhoneInputInput:focus,
.phone-input-field input:focus {
  box-shadow: none;
  height: 44px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  color: #344054;
  background-color: transparent;
}

.phone-input-field .PhoneInput .PhoneInputCountry {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  padding-left: 14px;
  border-radius: 8px;
}

.phone-input-field .PhoneInput .PhoneInputCountry .PhoneInputCountryIconUnicode {
  font-size: 16px;
  font-weight: 400;
  color: #344054;
}

.phone-input-field .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
  width: 8px;
  height: 8px;
  margin-bottom: 3px;
  margin-left: 10px;
  border-color: #8e8f98;
  opacity: 100;
}

.PhoneInputCountryIcon {
  display: none;
}

.custom-otp-input-profile div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.custom-otp-input div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.custom-otp-input-profile input {
  width: 48px !important;
  height: 48px !important;
  border-radius: 8px;
  border: 2px solid #d0d5dd;
  font-size: 28px;
  font-weight: 500;
  color: #11c182;
  margin-right: 0 !important;
}

.custom-otp-input input {
  width: 80px !important;
  height: 80px !important;
  border-radius: 12px;
  border: 2px solid #d0d5dd;
  font-size: 48px;
  font-weight: 500;
  color: #11c182;
  margin-right: 0 !important;
}

.custom-otp-input input:focus,
.custom-otp-input-profile input:focus {
  border-color: #11c182 !important;
  outline: none !important;
  box-shadow: none;
}

[data-testid="otp-input-root"] input[value]:not([value=""]) {
  border-color: #11c182;
}

.pac-container {
  /* z-index: 9 !important; */
  position: absolute !important;
  margin-top: 8px;
}
.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* width */
  ::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
  }

  .hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .custom-otp-input div {
    gap: 8px;
  }

  .custom-otp-input input {
    width: 64px !important;
    height: 64px !important;
    border-radius: 10px;
  }
}

.welcomeCard-swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Raj_react-select {
  width: 100%;
}
.Raj_react-select__control {
  width: 100%;
  background-color: white;
  height: 2.5rem;
}

.Raj_react-select__menu {
  z-index: 100;
  text-align: left;
  cursor: pointer;
  background-color: var(--color-input-surface-background);
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
  border: var(--color-global-divider);
}
.Raj_react-select__placeholder {
  font-size: 16px;
  text-transform: capitalize;
  color: var(--color-gray-700);
}

.Raj_react-select__option {
  background-color: white;
  font-weight: 400;
  color: var(--color-option-text-default);
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}

.Raj_react-select__option:hover {
  background-color: var(--color-option-surface-hover);
  font-weight: 500;
  color: var(--color-option-text-default);
}

.Raj_react-select__option--is-selected {
  background-color: var(--color-option-surface-hover);
  font-weight: 500;
  color: var(--color-option-text-default);
}

.Raj_react-select__value-container {
  padding: 0 8px;
  width: 100%;
}

.Raj_react-select__input {
  margin: 0; /* Remove margin */
}
.custom-modal-shadow_template {
  box-shadow:
    0px 8px 8px -4px rgba(10, 13, 18, 0.03),
    0px 20px 24px -4px rgba(10, 13, 18, 0.08),
    0px 2px 2px -1.5px rgba(10, 13, 18, 0.04);
}

.custom-modal-dropdown {
  box-shadow:
    0px 2px 2px -1px rgba(10, 13, 18, 0.04),
    0px 4px 6px -2px rgba(10, 13, 18, 0.03),
    0px 12px 16px -4px rgba(10, 13, 18, 0.08);
}
.custom-primary-shadow {
  box-shadow:
    rgba(255, 255, 255, 0.12) 0px 2px 0px 0px inset,
    rgba(255, 255, 255, 0) 0px 0px 0px 1px inset;
}

.custom-outline-shadow {
  box-shadow:
    rgba(16, 24, 40, 0.05) 0px 1px 2px 0px,
    rgba(16, 24, 40, 0.05) 0px -2px 0px 0px inset,
    rgba(16, 24, 40, 0.18) 0px 0px 0px 1px inset;
}
.custom-modalbox-shadow {
  box-shadow:
    0px 3px 3px -1.5px rgba(10, 13, 18, 0.04),
    0px 8px 8px -4px rgba(10, 13, 18, 0.03),
    0px 20px 24px -4px rgba(10, 13, 18, 0.08);
}
.custom-modal-shadow {
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.custom-card {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.customShadowCard {
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
    0px 0px 0px 1px rgba(16, 24, 40, 0.05) inset;
}
.custom-card-buttom {
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
}
.welcomeCard-swiper .swiper-pagination-horizontal {
  margin: 24px 0px 24px 0px;
  bottom: 72px;
}

.welcomeCard-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.welcomeCard-swiper .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
}

.welcomeCard-swiper .swiper-pagination-bullet-active {
  border-radius: 8px;
  background-color: var(--color-global-primary);
}

.notification-shadow {
  box-shadow:
    0px 3px 3px -1.5px rgba(10, 13, 18, 0.04),
    0px 8px 8px -4px rgba(10, 13, 18, 0.03),
    0px 20px 24px -4px rgba(10, 13, 18, 0.08);
}

.global-search-shadow {
  box-shadow:
    0px 2px 2px -1px rgba(10, 13, 18, 0.04),
    0px 4px 6px -2px rgba(10, 13, 18, 0.03),
    0px 12px 16px -4px rgba(10, 13, 18, 0.08);
}
