:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary-50: #ecfdf3;
  --color-primary-100: #ccfbea;
  --color-primary-200: #abefc6;
  --color-primary-300: #5cf1bb;
  --color-primary-400: #24eca4;
  --color-primary-500: #11c182;
  --color-primary-600: #17b26a;
  --color-primary-700: #067647;
  --color-primary-800: #06422d;
  --color-primary-900: #101828;
  --color-primary-950: #000302;

  --color-orange-50: #fff2e6;
  --color-orange-100: #ffe7d1;
  --color-orange-200: #ffb380;
  --color-orange-300: #ffd2a8;
  --color-orange-400: #ffa757;
  --color-orange-450: #f79009;
  --color-orange-500: #ff922e;
  --color-orange-600: #f57500;
  --color-orange-700: #bd5a00;
  --color-orange-800: #853f00;
  --color-orange-900: #4d2500;

  --color-gray-25: #fff;
  --color-gray-50: #fcfcfc;
  --color-gray-100: #f7f8fc;
  --color-gray-200: #f9fafb;
  --color-gray-300: #e4e7ec;
  --color-gray-400: #d5d7da;
  --color-gray-450: #d0d5dd;
  --color-gray-500: #98a2b3;
  --color-gray-600: #8e8f98;
  --color-gray-700: #717680;
  --color-gray-800: #535862;
  --color-gray-900: #414651;
  --color-gray-925: #444656;
  --color-gray-950: #101828;
  --color-gray-900-inactive: #34405499;
  --color-gray-960: #363740;
  --color-gray-980: #0000001a;
  --color-gray-extra-2: #475467;
  --color-gray-extra-3: #a4a7ae;
  --color-gray-extra-4: #667085;
  --color-gray-extra-5: #111928;
   --color-gray-extra-6: #344054;
   --color-gray-extra-7:#E6E6E6;

  --color-gray-secondary-100: #182230;
  --color-gray-secondary-700: #414651;
  --color-gray-secondary-800: #535862;
  --color-gray-secondary-900: #e9eaeb;

  --color-red-50: #fef3f2;
  --color-red-100: #ffe9e5;
  --color-red-200: #fecdca;
  --color-red-300: #ffa194;
  --color-red-400: #ff7d6b;
  --color-red-500: #f04438;
  --color-red-550: #ff614b;
  --color-red-600: #b42318;

  --color-red-700: #d11a00;
  --color-red-800: #991300;
  --color-red-900: #610c00;

  --color-blue-50: #e4f1fd;
  --color-blue-100: #d0e7fc;
  --color-blue-200: #aad3f9;
  --color-blue-300: #84bff6;
  --color-blue-400: #5eabf4;
  --color-blue-500: #3897f1;
  --color-blue-600: #107be1;
  --color-blue-700: #0c5fad;
  --color-blue-800: #084278;
  --color-blue-900: #052544;
  --color-tertiary-600: #535862;

  /* Global Color */
  --color-global-primary: var(--color-primary-500);
  --color-global-primary-light: var(--color-primary-50);
  --color-global-gray: var(--color-gray-500);
  --color-global-gray-brand: var(--color-gray-900);
  --color-global-gray-medium: var(--color-gray-400);
  --color-global-white: var(--color-gray-200); /* Fibervue white → bg2 */
  --color-global-theme: var(--color-white); /* Theme colour →  Neutral Color */
  --color-global-surface: var(--color-gray-200); /* Surface →  App bg */
  --color-global-divider-soft: var(--color-gray-300);
  --color-global-divider: var(--color-gray-100);
  --color-global-divider-medium: var(--color-gray-980);
  --color-global-title: var(--color-gray-950);
  --color-global-title-2: var(--color-gray-900);
  --color-global-blue: var(--color-blue-500);
  --color-global-paragraph: var(--color-gray-800);
  --color-global-paragraph-2: var(--color-gray-700);
  --color-global-paragraph-3:var(--color-gray-extra-6);
  --color-global-gray-soft: var(--color-gray-300);
  --color-global-gray-light: var(--color-gray-50);
  --color-global-gray-brand-inactive: var(--color-gray-900-inactive);
  --color-global-gray-extra: var(--color-gray-960);
  --color-global-sidebar-bg: var(--color-gray-960);
  --color-global-danger: var(--color-red-500);
  --color-global-warning: var(--color-orange-450);

  --color-global-white-default: var(--color-white);

  /* Button Color */
  --color-btn-surface-disabled: var(--color-gray-300);
  --color-btn-surface-primary: var(--color-primary-600);
  --color-btn-surface-secondary: var(--color-gray-25);
  --color-btn-surface-secondary-hover: var(--color-gray-950);
  --color-btn-surface-tertiary-hover: var(--color-gray-200);
  --color-btn-surface-outline-hover: var(--color-gray-200);
  --color-btn-surface-warning: var(--color-red-500);
  --color-btn-text-default: var(--color-gray-800);
  --color-btn-text-secondary: var(--color-gray-50);
  --color-btn-text-primary-disabled: var(--color-gray-500);
  --color-btn-text-secondary-disabled: var(--color-gray-700);
  --color-btn-text-tertiary-disabled: var(--color-gray-400);
  --color-btn-text-tertiary-primary-disabled: var(--color-gray-500);
  --color-btn-stroke-default: var(--color-gray-400);
  --color-btn-text-link: var(--color-gray-950);
  --color-btn-text-link-hover: var(--color-primary-500);
  --color-option-text-default: var(---color-gray-800);
  --color-btn-text-tertiary: var(--color-gray-800);
  --color-btn-text-tertiary-hover: var(--color-gray-950);
  --color-txt-secondary-hover: var(--color-gray-secondary-100);
  --color-txt-secondary-secondary: var(--color-gray-secondary-700);
  --color-txt-secondary: var(--color-gray-900);
  --color-bg-primary-hover: var(--color-gray-200);
  --color-bg-primary: var(--color-white);
  --color-text-primary: var(--color-gray-950);
  --color-btn-gray-extra:var( --color-gray-extra-7);

  /*Border color */
  --color-border-primary: var(--color-gray-400);
  --color-border-secondary: var(--color-gray-secondary-900);
  --color-divider-stroke: var(--color-gray-300);

  /* Breadcrumb color */
  --color-breadcrumb-txt-bg: var(--color-gray-800);

  /* Card color */
  --color-card-surface-background: var(--color-gray-50);
  --color-card-text-title: var(--color-gray-500);
  --color-card-text-data-title: var(--color-gray-500);
  --color-card-text-data: var(--color-gray-800);
  --color-card-divider: var(--color-gray-100);
  --color-card-small-title: var(--color-gray-800);

  /* Dropdown color */
  --color-dropdown-surface-background: var(--color-white);
  --color-dropdown-surface-hover: var(--color-gray-100);
  --color-dropdown-text-data: var(--color-gray-800);
  --color-dropdown-text-label: var(--color-gray-800);
  --color-dropdown-text-placeholder: var(--color-gray-500);

  /* Menu Item */
  --color-menu-item-surface-active: var(--color-gray-800);

  /* Icon colors */
  --color-icon-fill-default: var(--color-gray-925);
  --color-icon-default-2: var(--color-gray-950);
  --color-icon-default-4: var(--color-gray-700);
  --color-icon-default-5: var(--color-gray-extra-3);
  --color-icon-fill-light: var(--color-gray-50);
  --color-icon-fill-active: var(--color-primary-500);
  --color-icon-fill-table: var(--color-gray-500);
  --color-icon-fill-input: var(--color-gray-600);
  --color-icon-fill-disable: var(--color-gray-400);
  --color-icon-fill-secondary-button: var(--color-gray-50);
  --color-icon-fill-notification-unread: var(--color-primary-500);
  --color-icon-fill-notification-read: var(--color-gray-600);
  --color-icon-fill-danger: var(--color-red-500);
  --color-login-icon-outlined-default: var(--color-gray-900);
  --color-login-icon-outlined-disabled: var(--color-gray-600);
  --color-icon-outline-default-hover: var(--color-gray-900);
  --color-icon-outline-default: var(--color-gray-800);
  --color-icon-outline-input: var(--color-gray-600);
  --color-icon-outline-light: var(--color-gray-50);
  --color-icon-outline-active: var(--color-primary-500);
  --color-icon-outline-danger: var(--color-red-500);
  --color-fg-quantary: var(--color-gray-extra-4);

  /* Input Field Color */
  --color-input-surface-background: var(--color-white);
  --color-input-surface-hover: var(--color-gray-200);
  --color-input-suerface-disabled: var(--color-gray-100);
  --color-input-text-data: var(--color-gray-900);
  --color-input-text-label: var(--color-gray-extra-2);
  --color-input-text-placeholder: var(--color-gray-700);
  --color-input-text-placeholder-extra: var(--color-gray-extra-4);
  --color-input-text-support: var(--color-gray-800);
  --color-input-text-error: var(--color-red-500);
  --color-input-text-warning: var(--color-orange-500);
  --color-input-text-success: var(--color-primary-500);
  --color-input-stroke-default: var(--color-gray-400);
  --color-input-stroke-active: var(--color-primary-500);
  --color-input-support-text: var(--color-gray-800);

  /* Info Card Colors */
  --color-infocard-surface-background: var(--color-gray-50);
  --color-infocard-text-title: var(--color-gray-800);
  --color-infocard-text-data: var(--color-gray-800);
  --color-infocard-text-title-light: var(--color-gray-50);
  --color-infocard-text-data-light: var(--color-gray-50);
  --color-infocard-stroke-divider: var(--color-gray-100);

  /* Login color */
  --color-login-background: var(--color-white);
  --color-login-text-title: var(--color-primary-900);
  --color-login-text-paragraph: var(--color-tertiary-600);
  --color-login-stroke: var(--color-gray-800);

  /* Notification Color */
  --color-notification-surface-background: var(--color-white);
  --color-notification-text-unread: var(--color-gray-900);
  --color-notification-text-read: var(--color-gray-600);
  --color-notification-stroke-hover: var(--color-gray-200);

  /* Progress Bar Colors */
  --color-progress-surface-green: var(--color-primary-500);
  --color-progress-surface-yellow: var(--color-orange-500);
  --color-progress-surface-red: var(--color-red-500);
  --color-progress-surface-blue: var(--color-blue-500);
  --color-progress-circle-surface-background: var(--color-gray-200);
  --color-progress-circle-surface-element: var(--color-gray-50);
  --color-progress-line-surface-background: var(--color-gray-300);
  --color-progress-surface-step-active: var(--color-primary-500);
  --color-progress-surface-step-inactive: var(--color-gray-700);

  /* Search Result Color */
  --color-searchresult-surface-background: var(--color-gray-100);
  --color-searchresult-category: var(--color-primary-500);
  --color-searchresult-text: var(--color-gray-800);
  --color-searchresult-subtext: var(--color-gray-500);

  /* Toggle Color */
  --color-toggle-surface-background: var(--color-gray-200);
  --color-toggle-active-switch: var(--color-white);
  --color-toggle-inactive-switch: var(--color-gray-300);
  --color-toggle-round-switch: var(--color-white);
  --color-toggle-text: var(--color-gray-800);
  --color-toggle-switch: var(--color-gray-800);
  --color-toggle-stroke-default: var(--color-gray-300);
  --color-toggle-stroke-active: var(--color-gray-400);
  --color-toggle-text-default: var(--color-gray-700);
  --color-toggle-text-active: var(--color-gray-900);

  /* Table Color */
  --color-table-surface-row-background: var(--color-white);
  --color-table-text-header: var(--color-gray-800);
  --color-table-text-data-dark: var(--color-primary-900);
  --color-table-text-data: var(--color-gray-800);
  --color-text-data-dark: var(--color-gray-extra-5);
  --color-table-stroke-row-divider: var(--color-gray-300);

  /* Tags Color */
  --color-tag-surface-background: var(--color-gray-100);
  --color-tag-text: var(--color-gray-800);

  /* Tab Color */
   
  --color-dropdown-stroke-default: var(--color-gray-500);
   --color-dropdown-stroke-default-2: var(--color-gray-400);
  --color-dropdown-stroke-active: var(--color-gray-500);
  --color-tab-surface-default: var(--color-gray-50);
  --color-tab-surface-active: var(--color-gray-200);

  --color-tab-text-default: var(--color-gray-800);
  --color-tab-text-active: var(--color-gray-800);

  /* Set background color */
  background-color: var(--color-global-surface);

  /* Options */
  --color-option-surface-default: var(--color-gray-50);
  --color-option-surface-hover: var(--color-gray-200);
  --color-option-surface-hover-red: var(--color-red-100);
  --color-option-text-default: var(--color-gray-800);
  --color-option-text-hover-red: var(--color-red-500);

  /* Status */
  --color-status-surface-default: var(--color-gray-200);
  --color-status-surface-gray: var(--color-gray-100);
  --color-status-surface-yellow: var(--color-orange-50);
  --color-status-surface-red: var(--color-red-50);
  --color-status-surface-green: var(--color-primary-50);
  --color-status-text-gray: var(--color-primary-850);
  --color-status-text-yellow: var(--color-orange-600);
  --color-status-text-red: var(--color-red-600);
  --color-status-text-green: var(--color-primary-700);
  --color-status-text-default: var(--color-gray-900);
  --color-status-icon-default: var(--color-gray-700);
  --color-status-icon-green: var(--color-primary-600);
  --color-status-icon-red: var(--color-red-500);
  --color-status-stroke-default: var(--color-gray-300);
  --color-status-stroke-green: var(--color-primary-200);
  --color-status-stroke-red: var(--color-red-200);

  /* Info */
  --color-info-surface-default: var(--color-gray-100);
  --color-info-surface-info: var(--color-orange-50);
  --color-info-surface-danger: var(--color-red-50);
  --color-info-surface-success: var(--color-primary-50);
  --color-info-text-default: var(--color-gray-800);
  --color-info-text-info: var(--color-orange-600);
  --color-info-text-danger: var(--color-red-500);
  --color-info-text-success: var(--color-primary-600);

  /*Tooltip*/
  --color-tooltip-surface-light: var(--color-gray-50);
  --color-tooltip-surface-dark: var(--color-gray-800);
  --color-tooltip-text-light: var(--color-gray-800);
  --color-tooltip-text-dark: var(--color-gray-50);

  /* Modal */
  --color-modal-surface-background: var(--color-gray-50);
  --color-modal-text-title: var(--color-gray-800);
  --color-modal-text-paragraph: var(--color-gray-800);

  /*Toaster Color */
  --color-toast-text-title: var(--color-gray-900);
  --color-toast-text-subtitle: var(--color-gray-800);

  /*Text Color */
  --color-text-quaternary: var(--color-gray-700);
}

.dark {
  /* Global Color */
  --color-global-primary: var(--color-primary-500);
  --color-global-primary-light: var(--color-primary-950);
  --color-global-gray: var(--color-gray-500);
  --color-global-gray-brand: var(--color-gray-50);
  --color-global-white: var(--color-gray-900);
  --color-global-theme: var(--color-black);
  --color-global-surface: var(--color-gray-950);
  --color-global-divider-soft: var(--color-gray-700);
  --color-global-divider: var(--color-gray-900);
  --color-global-divider-medium: var(--color-gray-300);
  --color-global-title: var(--color-gray-50);
  --color-global-blue: var(--color-blue-500);
  --color-global-gray-light: var(--color-gray-700);

  /* Button Color */
  --color-btn-surface-disabled: var(--color-gray-700);
  --color-btn-surface-primary: var(--color-primary-600);
  --color-btn-surface-secondary: var(--color-gray-100);
  --color-btn-surface-secondary-hover: var(--color-gray-50);
  --color-btn-surface-tertiary-hover: var(--color-gray-800);
  --color-btn-surface-outline-hover: var(--color-gray-800);

  --color-btn-text-default: var(--color-gray-50);
  --color-btn-text-secondary: var(--color-gray-800);
  --color-btn-text-primary-disabled: var(--color-gray-500);
  --color-btn-text-secondary-disabled: var(--color-gray-600);
  --color-btn-text-tertiary-disabled: var(--color-gray-800);
  --color-btn-text-tertiary-primary-disabled: var(--color-gray-700);

  --color-btn-stroke-default: var(--color-gray-50);

  /* Card color */
  --color-card-surface-background: var(--color-gray-900);
  --color-card-text-title: var(--color-gray-500);
  --color-card-text-data-title: var(--color-gray-400);
  --color-card-text-data: var(--color-gray-50);
  --color-card-divider: var(--color-gray-800);
  --color-card-small-title: var(--color-gray-50);

  /* Dropdown color */
  --color-dropdown-surface-background: var(--color-gray-900);
  --color-dropdown-surface-hover: var(--color-gray-800);
  --color-dropdown-text-data: var(--color-gray-50);
  --color-dropdown-text-label: var(--color-gray-50);
  --color-dropdown-text-placeholder: var(--color-gray-600);
  --color-dropdown-stroke-default: var(--color-gray-500);
  --color-dropdown-stroke-active: var(--color-primary-500);

  /* Icon colors */
  --color-icon-fill-default: var(--color-gray-50);
  --color-icon-fill-light: var(--color-gray-50);
  --color-icon-fill-active: var(--color-primary-500);
  --color-icon-fill-table: var(--color-gray-500);
  --color-icon-fill-input: var(--color-gray-50);
  --color-icon-fill-disable: var(--color-gray-800);
  --color-icon-fill-secondary-button: var(--color-gray-800);
  --color-icon-fill-notification-unread: var(--color-primary-500);
  --color-icon-fill-notification-read: var(--color-gray-600);
  --color-icon-fill-danger: var(--color-red-400);

  --color-icon-outline-default: var(--color-gray-50);
  --color-icon-outline-input: var(--color-gray-50);
  --color-icon-outline-light: var(--color-gray-800);
  --color-icon-outline-active: var(--color-primary-500);
  --color-icon-outline-danger: var(--color-red-400);

  /* Input Field Color */
  --color-input-surface-background: var(--color-gray-800);
  --color-input-surface-hover: var(--color-gray-800);
  --color-input-suerface-disabled: var(--color-gray-800);
  --color-input-text-data: var(--color-gray-50);
  --color-input-text-label: var(--color-gray-900);
  --color-input-text-placeholder: var(--color-gray-600);
  --color-input-text-support: var(--color-gray-600);
  --color-input-text-error: var(--color-red-500);
  --color-input-text-warning: var(--color-orange-500);
  --color-input-text-success: var(--color-primary-500);
  --color-input-stroke-default: var(--color-gray-500);
  --color-input-stroke-active: var(--color-primary-500);

  /* Info Card Colors */
  --color-infocard-surface-backfround: var(--color-gray-900);
  --color-infocard-text-title: var(--color-gray-50);
  --color-infocard-text-data: var(--color-gray-50);
  --color-infocard-text-title-light: var(--color-gray-50);
  --color-infocard-text-data-light: var(--color-gray-50);
  --color-infocard-stroke-divider: var(--color-gray-800);

  /* Login color */
  --color-login-background: var(--color-gray-900);
  --color-login-text-title: var(--color-gray-50);
  --color-login-text-paragraph: var(--color-gray-400);
  --color-login-stroke: var(--color-gray-50);

  /* Notification Color */
  --color-notification-surface-background: var(--color-gray-950);
  --color-notification-text-unread: var(--color-white);
  --color-notification-text-read: var(--color-gray-600);
  --color-notification-stroke-hover: var(--color-gray-700);

  /* Progress Bar Colors */
  --color-progress-surface-green: var(--color-primary-500);
  --color-progress-surface-yellow: var(--color-orange-500);
  --color-progress-surface-red: var(--color-red-500);
  --color-progress-surface-blue: var(--color-blue-500);
  --color-progress-circle-surface-background: var(--color-gray-700);
  --color-progress-circle-surface-element: var(--color-gray-900);
  --color-progress-line-surface-background: var(--color-gray-700);
  --color-progress-surface-step-active: var(--color-primary-500);
  --color-progress-surface-step-inactive: var(--color-gray-700);

  /* Search Result Color */
  --color-searchresult-surface-background: var(--color-gray-700);
  --color-searchresult-surface-category: var(--color-primary-500);
  --color-searchresult-text: var(--color-white);
  --color-searchresult-subtext: var(--color-gray-400);

  /* Toggle Color */
  --color-toggle-surface-background: var(--color-gray-950);
  --color-toggle-active-switch: var(--color-primary-500);
  --color-toggle-inactive-switch: var(--color-gray-600);
  --color-toggle-round-switch: var(--color-white);
  --color-toggle-text: var(--color-gray-50);

  /* Table Color */
  --color-table-surface-row-background: var(--color-gray-900);
  --color-table-text-header: var(--color-gray-50);
  --color-table-text-data: var(--color-gray-50);
  --color-table-stroke-row-divider: var(--color-gray-800);

  /* Tags Color */
  --color-tag-surface-background: var(--color-gray-700);
  --color-tag-text: var(--color-white);

  /* Tab Color */
  --color-tab-surface-default: var(--color-gray-900);
  --color-tab-surface-active: var(--color-primary-950);

  --color-tab-text-default: var(--color-gray-100);
  --color-tab-text-active: var(--color-primary-500);

  /* Set background color */
  background-color: var(--color-global-surface);

  /* Options */
  --color-option-surface-default: var(--color-gray-900);
  --color-option-surface-hover: var(--color-gray-800);
  --color-option-surface-hover-red: var(--color-red-800);
  --color-option-text-default: var(--color-gray-50);
  --color-option-text-hover-red: var(--color-red-500);

  /* Status */
  --color-status-surface-gray: var(--color-gray-950);
  --color-status-surface-yellow: var(--color-orange-900);
  --color-status-surface-red: var(--color-red-900);
  --color-status-surface-green: var(--color-primary-950);
  --color-status-text-gray: var(--color-gray-50);
  --color-status-text-yellow: var(--color-orange-400);
  --color-status-text-red: var(--color-red-400);
  --color-status-text-green: var(--color-primary-400);

  /* Info */
  --color-info-surface-default: var(--color-gray-900);
  --color-info-surface-info: var(--color-orange-900);
  --color-info-surface-danger: var(--color-red-900);
  --color-info-surface-success: var(--color-primary-950);
  --color-info-text-default: var(--color-gray-50);
  --color-info-text-info: var(--color-orange-500);
  --color-info-text-danger: var(--color-red-500);
  --color-info-text-success: var(--color-primary-500);

  /*Tooltip*/
  --color-tooltip-surface-light: var(--color-gray-950);
  --color-tooltip-surface-dark: var(--color-gray-50);
  --color-tooltip-text-light: var(--color-gray-100);
  --color-tooltip-text-dark: var(--color-gray-800);

  /* Modal */
  --color-modal-surface-background: var(--color-gray-900);
  --color-modal-text-title: var(--color-gray-50);
  --color-modal-text-paragraph: var(--color-gray-50);
}
